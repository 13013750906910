type Options = {
    short?: boolean
    onlyYear?: boolean
}

export const getFormattedDate = (
    date: string | undefined,
    options?: Options
) => {
    if (!date) return 'Desconocido'

    if (options?.short) {
        return new Date(date).toLocaleDateString('es-ES', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: 'UTC',
        })
    }

    if (options?.onlyYear) {
        return new Date(date).toLocaleDateString('es-ES', {
            year: 'numeric',
        })
    }

    return new Date(date).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    })
}

export const getDifferenceBetweenTwoDatesInDays = (
    endDate: string,
    startDate: string | Date = new Date()
) => {
    const end = new Date(endDate)
    const start = new Date(startDate)
    return Math.abs(~~((end.getTime() - start.getTime()) / (1000 * 3600 * 24)))
}
