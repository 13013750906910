import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSongDuration } from 'util/time'
import { getFormattedDate } from 'util/date'
// SVG
import Translation from 'svg/translation'
// Models
import { ISongCard } from 'models/Song'
// Styles
import * as styles from './SongCard.module.css'

type SongCardProps = {
    song: ISongCard
}

const SongCard: React.FC<SongCardProps> = ({ song }) => {
    const { name, slug, thumbnail, release_date } = song
    const duration = getSongDuration(song)
    const date = getFormattedDate(release_date, { onlyYear: true })

    return (
        <Link className={styles.song_card} to={`/songs/${slug}`}>
            <GatsbyImage
                className={styles.song_card_thumbnail}
                image={thumbnail.gatsbyImageData}
                alt={name}
            />
            <h2 className={styles.song_card_name}>
                {name}
                {song.lyric_es && <Translation className={styles.translated} />}
                {song.cc_es && (
                    <FontAwesomeIcon
                        className={styles.cc}
                        icon={['far', 'closed-captioning']}
                    />
                )}
            </h2>
            <span className={styles.song_card_date}>
                <FontAwesomeIcon
                    className={styles.song_card_icon}
                    icon={['far', 'calendar-alt']}
                />
                {date}
            </span>
            <span className={styles.song_card_duration}>
                <FontAwesomeIcon
                    className={styles.song_card_icon}
                    icon={['far', 'clock']}
                />
                {duration}
            </span>
        </Link>
    )
}

export default SongCard
