import React from 'react'
import SongCard from './SongCard'
// Models
import { ISongCard } from 'models/Song'

type SongsListProps = {
    songs: ISongCard[]
}

const SongsList: React.FC<SongsListProps> = ({ songs }) => {
    return (
        <>
            {songs.map((song) => (
                <SongCard key={song.slug} song={song} />
            ))}
        </>
    )
}

export default SongsList
