import React from 'react'
// Components
import Content from 'components/Layout/Content'
import DefaultSidebar from 'components/Layout/Sidebar/DefaultSidebar'
import SongsList from './SongsList'
import PageNav from 'components/UIElements/Navigation/PageNav'
// Models
import { ISongCard } from 'models/Song'
import { IContextWithPageNav } from 'models/Context'
// Styles
import * as styles from './SongsContainer.module.css'

type SongsContainerProps = {
    songs: ISongCard[]
    context: IContextWithPageNav
}

const SongsContainer: React.FC<SongsContainerProps> = ({ songs, context }) => {
    const { currentPage, totalPages } = context
    return (
        <section className={styles.songs}>
            <Content>
                <SongsList songs={songs} />
                <PageNav
                    to="/songs"
                    currentPage={currentPage}
                    totalPages={totalPages}
                />
            </Content>
            <DefaultSidebar />
        </section>
    )
}

export default SongsContainer
