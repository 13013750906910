import { ISong, ISongCard } from 'models/Song'

const getDurationFromMS = (duration: number) => {
    const hours = Math.floor((duration / 3.6e6) % 24)
    const minutes = Math.floor((duration / 60000) % 60)
    const seconds = Math.floor((duration / 1000) % 60)
    return { hours, minutes, seconds }
}

const getFormattedTime = (duration: number) => {
    const { hours, minutes, seconds } = getDurationFromMS(duration)

    let hoursText: string | null = null
    if (hours > 1) hoursText = `${hours} h`
    if (hours === 1) hoursText = `${hours} h`

    let minutesText: string | null = null
    if (minutes > 1) minutesText = `${minutes} min`
    if (minutes === 1) minutesText = `${minutes} min`

    let secondsText: string | null = null
    if (seconds > 1) secondsText = `${seconds} s`
    if (seconds === 1) secondsText = `${seconds} s`

    if (hoursText && minutesText && secondsText) {
        return `${hoursText} con ${minutesText} y ${secondsText}`
    }

    if (!hoursText && minutesText && secondsText) {
        return `${minutesText} y ${secondsText}`
    }

    if (!hoursText && !minutesText && secondsText) {
        return `${secondsText}`
    }

    if (hoursText && !minutesText && secondsText) {
        return `${hoursText} y ${secondsText}`
    }

    if (hoursText && !minutesText && !secondsText) {
        return `${hoursText}`
    }

    if (hoursText && minutesText && !secondsText) {
        return `${hoursText} con ${minutesText}`
    }
}

export const getDiskDuration = (songs: ISong[] | ISongCard[]) => {
    let duration = 0
    songs.map((song) => (duration += song.duration ? song.duration : 0))
    return getFormattedTime(duration)
}

export const getSongDuration = (song: ISong | ISongCard) => {
    if (!song.duration) return 'Desconocido'
    const { minutes, seconds } = getDurationFromMS(song.duration)
    if (minutes === 0 && seconds === 0) return 'Desconocido'
    if (seconds < 10) return `${minutes}:0${seconds}`
    return `${minutes}:${seconds}`
}
