import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { IContextWithPageNav } from 'models/Context'
// Components
import Layout from 'components/Layout'
import SectionHeader from 'components/Layout/SectionHeader'
import SongsContainer from 'components/Sections/Songs/SongsContainer'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'
import { ISong } from 'models/Song'

type SongsListPageProps = {
    songs: {
        nodes: ISong[]
    }
    pageSeo: ISeoQuery
}

const SongsListPage: React.FC<
    PageProps<SongsListPageProps, IContextWithPageNav>
> = ({ data, pageContext: context }) => {
    const songs = data.songs.nodes
    const pageSeo = data.pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }
    return (
        <Layout seo={seo}>
            <SectionHeader title={pageSeo.title} image={pageSeo.image} />
            <SongsContainer songs={songs} context={context} />
        </Layout>
    )
}

export default SongsListPage

export const pageQuery = graphql`
    query SongsListPage($limit: Int!, $skip: Int!) {
        songs: allContentfulSongs(
            limit: $limit
            skip: $skip
            sort: { fields: release_date, order: DESC }
        ) {
            nodes {
                name
                slug

                release_date
                duration

                thumbnail {
                    gatsbyImageData(width: 50)
                }

                cc_es {
                    file {
                        url
                        fileName
                        contentType
                    }
                }

                lyric_es {
                    raw
                }
            }
        }

        pageSeo: contentfulSeo(slug: { eq: "songs" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
